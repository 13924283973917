import { Component } from 'preact';
import Portal from 'preact-portal';
import htmlClassNames from 'classnames';
import { asyncComponent } from 'core/components/Hoc/asyncComponent';
import { withFormNoticeService } from 'core/services/FormNoticeService';
import { FormNoticeService } from 'core/services/FormNoticeService';
import { OPERATION_CHANGE_CITY, OPERATION_CHANGE_DELIVERY_METHOD, OPERATION_CHANGE_FIELDS, OPERATION_CHANGE_INLINE_ADDRESS, OPERATION_CHANGE_PAYMENT_METHOD, OPERATION_CHANGE_ADDRESS_COORDS, OPERATION_CHANGE_TERMINAL, OPERATION_CHANGE_CUSTOMER_TYPE, OPERATION_CUSTOM_CHANGE_ADDRESS } from 'site/VControllers/components/Checkout/operations/form';
import h from './cssScope';
import { Field } from './components/Field';
import { DEFAULT_COUNTRY_ID } from 'site/global/constants';
import { BUYER_GROUP_KEY, DeliveryMethod, FieldsKeys } from './constants';
export { FieldsKeys, Field, BUYER_GROUP_KEY };
const Modal = asyncComponent(() => import(
/* webpackChunkName: "site_c-TotalBlockInstallmentsModal" */
'site/VControllers/components/Checkout/components/TotalBlock/components/PaymentOptions/components/Modal'), 'Modal');
export const INDIVIDUAL_BUYER_GROUP = {
    name: 'Покупатель',
    key: BUYER_GROUP_KEY,
    rows: [
        [FieldsKeys.CustomerType],
        [FieldsKeys.FirstName, FieldsKeys.LastName],
        [FieldsKeys.Phone, FieldsKeys.Email]
    ]
};
export const LEGAL_BUYER_GROUP = {
    name: 'Покупатель',
    key: BUYER_GROUP_KEY,
    rows: [
        [FieldsKeys.CustomerType],
        [FieldsKeys.FirstName],
        [FieldsKeys.Inn],
        [FieldsKeys.Phone, FieldsKeys.Email]
    ]
};
const DEFAULT_ADDRESS_GROUP = {
    name: 'Адрес доставки',
    key: 'address',
    rows: [
        [FieldsKeys.Country, FieldsKeys.Settlement],
        [FieldsKeys.DeliveryMethod],
        [FieldsKeys.InlineAddress, FieldsKeys.Apartment],
        [FieldsKeys.AddressMap],
        [FieldsKeys.Comment]
    ]
};
const ADDRESS_GROUP = {
    name: 'Адрес доставки',
    key: 'address',
    rows: [
        [FieldsKeys.DeliveryMethod],
        [FieldsKeys.Country, FieldsKeys.City],
        [FieldsKeys.Address],
        [FieldsKeys.AddressMap],
        [FieldsKeys.Comment]
    ]
};
const ADDRESS_PICKUP_GROUP = {
    name: 'Адрес доставки',
    key: 'address',
    rows: [
        [FieldsKeys.Country, FieldsKeys.Settlement],
        [FieldsKeys.DeliveryMethod],
        [FieldsKeys.Terminal]
    ]
};
const OZON_PICKUP_GROUP = {
    name: 'Адрес доставки',
    key: 'address',
    rows: [
        [FieldsKeys.Country, FieldsKeys.Settlement],
        [FieldsKeys.DeliveryMethod]
    ]
};
const YANDEX_PICKUP_GROUP = {
    name: 'Адрес доставки',
    key: 'address',
    rows: [
        [FieldsKeys.Country],
        [FieldsKeys.Settlement],
        [FieldsKeys.DeliveryMethod],
        [FieldsKeys.Yandex]
    ]
};
const IMR_PICKUP_GROUP = {
    name: 'Адрес доставки',
    key: 'address',
    rows: [
        [FieldsKeys.DeliveryMethod]
    ]
};
const CONFIG = {
    defaultAddressGroup: DEFAULT_ADDRESS_GROUP,
    addressGroup: ADDRESS_GROUP,
    addressPickupGroup: ADDRESS_PICKUP_GROUP,
    ozonPickupGroup: OZON_PICKUP_GROUP,
    yandexPickupGroup: YANDEX_PICKUP_GROUP,
    imrPickupGroup: IMR_PICKUP_GROUP
};
const PAYMENT_METHOD_GROUPS = [
    {
        name: 'Способ оплаты',
        key: 'payment',
        rows: [
            [FieldsKeys.PaymentMethod]
        ]
    }
];
const FORM_NAME = 'cart_form';
class FormComponent extends Component {
    constructor() {
        super(...arguments);
        this.state = {
            useDefaultFields: true,
            isModalOpen: false
        };
        this.buildModal = () => {
            const isNotRendered = !document.getElementsByClassName('b-FrameModal').length;
            if (isNotRendered)
                return (h(Portal, { into: 'body' },
                    h(Modal, { checkoutContext: this.context, onClose: this.handleCloseModal })));
        };
        this.handleOpenModal = () => this.setState({ isModalOpen: true });
        this.handleCloseModal = () => {
            this.setState({ isModalOpen: false });
            setTimeout(() => {
                const paymentMethod = this.paymentMethod;
                if (paymentMethod && !paymentMethod.value.toString().includes('installment')) {
                    this.context.store.operate(OPERATION_CHANGE_PAYMENT_METHOD, paymentMethod.value);
                }
            }, 300);
        };
        this.handleChangeField = (key, value) => {
            var _a, _b;
            switch (key) {
                case FieldsKeys.PaymentMethod:
                    if (value === 'installments')
                        return this.handleOpenModal();
                    this.context.store.operate(OPERATION_CHANGE_PAYMENT_METHOD, value);
                    break;
                case FieldsKeys.Address:
                    // case when not russia country
                    if ((value === null || value === void 0 ? void 0 : value.action) === undefined) {
                        value = { address: value, action: 'input' };
                    }
                    if (value.action === 'manual') {
                        this.context.store.operate(OPERATION_CUSTOM_CHANGE_ADDRESS, value.address);
                    }
                    value = value.address;
                    break;
                case FieldsKeys.City:
                    if (value)
                        this.context.store.operate(OPERATION_CHANGE_CITY, value);
                    break;
                case FieldsKeys.InlineAddress:
                    this.context.store.operate(OPERATION_CHANGE_INLINE_ADDRESS, value);
                    break;
                case FieldsKeys.DeliveryMethod:
                    this.context.store.operate(OPERATION_CHANGE_DELIVERY_METHOD, value);
                    break;
                case FieldsKeys.AddressMap:
                    this.context.store.operate(OPERATION_CHANGE_ADDRESS_COORDS, value);
                    break;
                case FieldsKeys.Terminal:
                    this.context.store.operate(OPERATION_CHANGE_TERMINAL, value);
                    break;
                case FieldsKeys.Country:
                    this.context.store.operate(OPERATION_CHANGE_ADDRESS_COORDS, null);
                    break;
                case FieldsKeys.CustomerType:
                    this.context.store.operate(OPERATION_CHANGE_CUSTOMER_TYPE, value);
                    break;
            }
            const inlineAddressField = this.fields.find(field => field.key === FieldsKeys.InlineAddress);
            const inlineSearchText = (_b = (_a = inlineAddressField === null || inlineAddressField === void 0 ? void 0 : inlineAddressField.items[0]) === null || _a === void 0 ? void 0 : _a.children[0]) === null || _b === void 0 ? void 0 : _b.searchText;
            const changedFields = this.fields.map((item) => {
                if (key === FieldsKeys.Country) {
                    switch (item.key) {
                        case FieldsKeys.City:
                        case FieldsKeys.InlineAddress: return { ...item, value: null };
                    }
                }
                if (key === FieldsKeys.City) {
                    switch (item.key) {
                        case FieldsKeys.Terminal:
                        case FieldsKeys.StreetAddress: return { ...item, value: null };
                    }
                }
                if (key === FieldsKeys.Settlement) {
                    switch (item.key) {
                        case FieldsKeys.StreetAddress: return { ...item, value: null };
                        case FieldsKeys.AddressMap: return { ...item, value: {} };
                        case FieldsKeys.InlineAddress: return { ...item, value: null, items: [] };
                    }
                }
                if (key === FieldsKeys.Address) {
                    switch (item.key) {
                        case FieldsKeys.StreetAddress:
                            if (item.value && value !== inlineSearchText)
                                return { ...item, value: null };
                    }
                }
                if (item.key === key)
                    return { ...item, value };
                return item;
            });
            this.context.store.operate(OPERATION_CHANGE_FIELDS, changedFields);
        };
        this.handleTurnOffDefaultFields = () => {
            this.handleChangeField(FieldsKeys.StreetAddress, null);
            this.setState({ useDefaultFields: false });
        };
        this.getFieldRefHandler = (fieldKey) => {
            const { onInputRef } = this.props;
            return (el) => onInputRef === null || onInputRef === void 0 ? void 0 : onInputRef(fieldKey, el);
        };
        this.renderGroup = (group) => {
            var _a, _b;
            const GroupChildren = (_b = (_a = this.props.config) === null || _a === void 0 ? void 0 : _a.groupChildren) === null || _b === void 0 ? void 0 : _b[group.key];
            return (h("div", { className: htmlClassNames('sb-Group', `__${group.key}`) },
                h("div", { className: 'sb-Group_title' }, group.name),
                h("div", { className: 'sb-Group_content ' },
                    GroupChildren && h(GroupChildren, null),
                    group.rows.map(row => (h("div", { className: 'sb-Row' }, row.map(this.renderField)))))));
        };
        this.renderField = (fieldKey) => {
            const field = this.fields.find(item => item.key === fieldKey);
            if (!(field === null || field === void 0 ? void 0 : field.isAvailable))
                return null;
            if (this.countryId !== DEFAULT_COUNTRY_ID && fieldKey === FieldsKeys.AddressMap)
                return null;
            if (field.key === FieldsKeys.City) {
                field.countryId = this.countryId;
            }
            if (fieldKey === FieldsKeys.PaymentMethod && this.isOzonPickupInputs) {
                field.items = field.items.filter((item) => item.id !== 'on_delivery');
            }
            // remove installments from payment method if country is not russia
            if (this.countryId !== DEFAULT_COUNTRY_ID && fieldKey === FieldsKeys.PaymentMethod) {
                const excludedInstallments = [
                    'installments',
                    'installment',
                    'installment_hkb',
                    'installment_podeli'
                ];
                field.items = field.items.filter((item) => !excludedInstallments.includes(item.id));
            }
            return (h("div", { className: htmlClassNames('sb-Row_item', this.createFieldModifier(fieldKey)) },
                h(Field, { field: field, formName: FORM_NAME, disabled: this.isDisabled, key: field.key, onChange: this.handleChangeField, onRef: this.getFieldRefHandler(field.key), formFields: this.fields, onTurnOffDefaultFields: this.handleTurnOffDefaultFields, focusOnLoad: this.shouldFocusOnLoad && this.focusOnLoadMap[fieldKey], notice: buildError(field.key) })));
        };
    }
    get focusOnLoadMap() {
        var _a;
        const map = {
            ...Object
                .values(FieldsKeys)
                .reduce((acc, key) => {
                acc[key] = false;
                return acc;
            }, {}),
            [FieldsKeys.Address]: true,
            [FieldsKeys.InlineAddress]: true
        };
        const firstNameValue = (_a = this.fields.find((field) => field.key === FieldsKeys.FirstName)) === null || _a === void 0 ? void 0 : _a.value;
        if (!firstNameValue) {
            map[FieldsKeys.FirstName] = true;
            map[FieldsKeys.Address] = false;
            map[FieldsKeys.InlineAddress] = false;
        }
        return map;
    }
    get shouldFocusOnLoad() {
        const { searchParams } = (new URL(window.location.href));
        return searchParams.get('autofocus') === 'true';
    }
    get currentState() {
        return this.context.store.getState();
    }
    get fields() {
        return this.currentState.formFields;
    }
    get isDisabled() {
        return this.context.currentState.loading;
    }
    get useDefaultInputs() {
        return this.countryId === DEFAULT_COUNTRY_ID && this.state.useDefaultFields;
    }
    get isPickupInputs() {
        const deliveryMethod = this.fields.find((field) => field.key === FieldsKeys.DeliveryMethod);
        return deliveryMethod && (deliveryMethod.value === DeliveryMethod.TerminalDelivery || deliveryMethod.value === DeliveryMethod.TerminalDeliveryKit);
    }
    get isOzonPickupInputs() {
        const deliveryMethod = this.fields.find((field) => field.key === FieldsKeys.DeliveryMethod);
        return deliveryMethod && deliveryMethod.value === DeliveryMethod.Pickup;
    }
    get isYandexPickupInputs() {
        const deliveryMethod = this.fields.find((field) => field.key === FieldsKeys.DeliveryMethod);
        return (!this.context.currentState.deliveryChanging &&
            deliveryMethod &&
            deliveryMethod.value === DeliveryMethod.YandexDelivery);
    }
    get isImrPickupInputs() {
        const deliveryMethod = this.fields.find((field) => field.key === FieldsKeys.DeliveryMethod);
        return deliveryMethod && deliveryMethod.value === DeliveryMethod.ImrPickup;
    }
    get countryId() {
        var _a;
        return (_a = this.fields.find((field) => field.key === FieldsKeys.Country)) === null || _a === void 0 ? void 0 : _a.value;
    }
    get buyerGroup() {
        var _a, _b, _c, _d, _e, _f, _g;
        const ct = (_a = this.fields.find((field) => field.key === FieldsKeys.CustomerType)) === null || _a === void 0 ? void 0 : _a.value;
        switch (ct) {
            case 'individual_entity':
                return (_c = (_b = this.props.config) === null || _b === void 0 ? void 0 : _b.individualBuyerGroup) !== null && _c !== void 0 ? _c : INDIVIDUAL_BUYER_GROUP;
            case 'legal_entity':
                return (_e = (_d = this.props.config) === null || _d === void 0 ? void 0 : _d.legalBuyerGroup) !== null && _e !== void 0 ? _e : LEGAL_BUYER_GROUP;
            default:
                return (_g = (_f = this.props.config) === null || _f === void 0 ? void 0 : _f.individualBuyerGroup) !== null && _g !== void 0 ? _g : INDIVIDUAL_BUYER_GROUP;
        }
    }
    get addressGroup() {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j;
        if (this.isPickupInputs)
            return (_b = (_a = this.props.config) === null || _a === void 0 ? void 0 : _a.addressPickupGroup) !== null && _b !== void 0 ? _b : ADDRESS_PICKUP_GROUP;
        if (this.isOzonPickupInputs)
            return (_d = (_c = this.props.config) === null || _c === void 0 ? void 0 : _c.ozonPickupGroup) !== null && _d !== void 0 ? _d : OZON_PICKUP_GROUP;
        if (this.isYandexPickupInputs)
            return (_f = (_e = this.props.config) === null || _e === void 0 ? void 0 : _e.yandexPickupGroup) !== null && _f !== void 0 ? _f : YANDEX_PICKUP_GROUP;
        if (this.isImrPickupInputs)
            return (_h = (_g = this.props.config) === null || _g === void 0 ? void 0 : _g.imrPickupGroup) !== null && _h !== void 0 ? _h : IMR_PICKUP_GROUP;
        const { addressGroup = ADDRESS_GROUP, defaultAddressGroup = DEFAULT_ADDRESS_GROUP } = (_j = this.props.config) !== null && _j !== void 0 ? _j : CONFIG;
        return this.useDefaultInputs ? defaultAddressGroup : addressGroup;
    }
    get groups() {
        const groups = [this.buyerGroup, this.addressGroup];
        if (this.isPaymentMethodBlank || this.isPaymentMethodHidden)
            return groups;
        return groups.concat(PAYMENT_METHOD_GROUPS);
    }
    get isPaymentMethodBlank() {
        return !this.fields.find((field) => field.key === FieldsKeys.PaymentMethod);
    }
    get isPaymentMethodHidden() {
        var _a;
        return ((_a = this.fields.find((field) => field.key === FieldsKeys.CustomerType)) === null || _a === void 0 ? void 0 : _a.value) === 'legal_entity';
    }
    get paymentMethod() {
        return this.context.currentState.formFields.find(field => field.key === 'payment_method');
    }
    createFieldModifier(key) {
        return `__${key.replace(/([-_][a-z])/ig, $1 => $1.toUpperCase().replace('_', ''))}`;
    }
    render() {
        const { onRef = () => { }, className } = this.props;
        const buildedClassName = htmlClassNames('s-CheckoutForm', className);
        return (h("form", { name: FORM_NAME, className: buildedClassName, ref: onRef },
            this.groups.map(this.renderGroup),
            this.state.isModalOpen && this.buildModal()));
    }
}
export const buildError = (field) => {
    const { errors } = FormNoticeService.notices;
    return errors[field] ? {
        type: 'error',
        message: errors[field].join(', ')
    } : undefined;
};
export const Form = withFormNoticeService(FormComponent);
