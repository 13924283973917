import { sendForm, updateCartInfo } from '../api/form';
import { pushToDataLayer } from 'core/services/GTM';
import { DEFAULT_CITY_ID, DEFAULT_COUNTRY_ID } from 'site/global/constants';
import { GtmAddressEvents } from 'site/VControllers/components/Checkout/constants';
import { COMMIT_CHANGE_FIELDS, COMMIT_SET_ORDER_INFO } from '../commits/form';
import { COMMIT_CHANGE_LOADING, COMMIT_CHANGE_DELIVERY_LOADING, COMMIT_VOID_DELIVERY_LOADING } from '../commits/changeLoading';
import { COMMIT_OPEN_REACHED_LIMIT_WARNING } from '../commits/reachedLimitWarning';
import { COMMIT_REFRESH_CHECKOUT } from '../commits/refreshCheckout';
import { COMMIT_SET_OZON_PICKUP } from '../commits/ozon';
import { COMMIT_SET_CONFIRMATION_TOKEN } from '../commits/payment';
import { COMMIT_SET_GTM_ADDRESS_EVENT } from '../commits/gtm';
import { COMMIT_SET_NOTICES, COMMIT_REMOVE_NOTICES } from '../commits/notices';
import { FieldsKeys } from 'site/VControllers/SinglePageCheckout/components/Form';
import { buildMd5 } from '../factories/AddressFactory';
import { getPickupPoints } from '../api/pickupPoints';
import { DeliveryMethod } from 'site/VControllers/SinglePageCheckout/components/Form/constants';
import { INLINE_ADDRESS_ID_REGEXP } from 'site/VControllers/SinglePageCheckout/services/fetchAddresses';
import { getSuggestionsByGeolocate } from 'site/VControllers/SinglePageCheckout/services/DaDataApi/api/suggestions';
const ADDRESS_WARNING_TEXT = 'Извините, мы не смогли найти адрес по вашим координатам, попробуйте ввести его вручную';
export function OPERATION_CHANGE_FIELDS(store) {
    return function (fields) {
        store.commit(COMMIT_CHANGE_FIELDS, fields, buildMd5(fields));
    };
}
export function OPERATION_SEND_FORM(store) {
    return function (options = { kp: false }) {
        const { kp = false } = options;
        store.commit(COMMIT_CHANGE_LOADING, true);
        const { totalPriceInfo: { showDeliveryPrice } } = store.getState();
        return sendForm(prepareFormData(store, kp))
            .then(({ data }) => {
            pushToDataLayer({ 'cartShowDeliveryPrice': `${showDeliveryPrice}` });
            pushToDataLayer({ event: 'cartOrderCreated' });
            pushGtmAddressEvent(store);
            pushToDataLayer(data.dataLayer);
            if (data.confirmation) {
                if (data.confirmation.action === 'redirect') {
                    setTimeout(() => { var _a; return window.location.href = (_a = data.confirmation) === null || _a === void 0 ? void 0 : _a.data; }, 500);
                }
                else if (data.confirmation.action === 'widget') {
                    store.commit(COMMIT_SET_ORDER_INFO, data.order);
                    store.commit(COMMIT_SET_CONFIRMATION_TOKEN, { token: data.confirmation.data });
                    setTimeout(() => store.commit(COMMIT_CHANGE_LOADING, false), 500);
                }
            }
            else {
                setTimeout(() => window.location.href = '/orders/thank_you', 500);
            }
        })
            .catch(({ status, data }) => {
            if (status === 422 && data.errorsData && data.errorsData.invalidItems) {
                store.commit(COMMIT_OPEN_REACHED_LIMIT_WARNING, data.errorsData);
            }
            handleAddressError(store, data.errors, status);
            store.commit(COMMIT_CHANGE_LOADING, false);
            return Promise.reject({ status, data });
        });
    };
}
const handleAddressError = (store, errors, status, isMobile = false) => {
    if (status === 422 && errors && errors.find(error => error.key === 'address')) {
        store.commit(COMMIT_SET_NOTICES, { [FieldsKeys.InlineAddress]: { type: 'error', message: ADDRESS_WARNING_TEXT } });
        const el = store.getState().formFieldsRefs[FieldsKeys.InlineAddress];
        if (el && isMobile) {
            setTimeout(() => { el.scrollIntoView({ behavior: 'smooth', block: 'center' }); }, 200);
        }
        else if (el && !isMobile) {
            el.scrollIntoView({ behavior: 'smooth', block: 'center' });
            el.focus();
        }
    }
};
export function OPERATION_CHANGE_PAYMENT_METHOD(store) {
    return function (paymentMethod) {
        var _a;
        if (((_a = store.getState().formFields.find(field => field.key === 'payment_method')) === null || _a === void 0 ? void 0 : _a.value) === paymentMethod)
            return;
        store.commit(COMMIT_CHANGE_LOADING, true);
        updateCartInfo({ paymentMethod })
            .then((result) => {
            store.commit(COMMIT_REFRESH_CHECKOUT, result.data);
        })
            .catch(() => {
            store.commit(COMMIT_CHANGE_LOADING, false);
        });
    };
}
export function OPERATION_CHANGE_DELIVERY_METHOD(store) {
    return function (deliveryType) {
        store.commit(COMMIT_CHANGE_DELIVERY_LOADING, true);
        return updateCartInfo({ deliveryType, dlTerminalId: null })
            .then((result) => {
            store.commit(COMMIT_REFRESH_CHECKOUT, result.data, COMMIT_VOID_DELIVERY_LOADING);
            store.operate(OPERATION_LOAD_PICKUP_POINTS);
            setTimeout(() => store.commit(COMMIT_CHANGE_DELIVERY_LOADING, false), 500);
        })
            .catch(() => {
            store.commit(COMMIT_CHANGE_DELIVERY_LOADING, false);
        });
    };
}
export function OPERATION_CHANGE_CITY(store) {
    return function (cityId) {
        const country = store.getState().formFields.find(({ key }) => key === FieldsKeys.Country);
        if (!country)
            return;
        store.commit(COMMIT_CHANGE_LOADING, true);
        return updateCartInfo({ cityId, countryId: country.value, pickupPointId: null, pickupPointCoordinates: null, streetAddressId: null })
            .then((result) => {
            store.commit(COMMIT_SET_OZON_PICKUP, { id: null, address: null });
            store.commit(COMMIT_REFRESH_CHECKOUT, result.data);
            store.operate(OPERATION_LOAD_PICKUP_POINTS);
        })
            .catch(() => {
            store.commit(COMMIT_CHANGE_LOADING, false);
        });
    };
}
export function OPERATION_CUSTOM_CHANGE_ADDRESS(store) {
    return function (address) {
        var _a;
        const formFields = store.getState().formFields;
        const country = formFields.find(field => field.key === FieldsKeys.Country);
        if (!country)
            return;
        const old = formFields.find(({ key }) => key === FieldsKeys.Address);
        if (!old)
            return;
        if (old.value === address)
            return;
        const city = (_a = formFields.find(({ key }) => key === FieldsKeys.Settlement)) === null || _a === void 0 ? void 0 : _a.value;
        if (city && !address.startsWith(String(city))) {
            address = [city, address].join(' ');
        }
        store.commit(COMMIT_CHANGE_LOADING, true);
        return updateCartInfo({ address, countryId: country.value })
            .then((result) => {
            store.commit(COMMIT_REFRESH_CHECKOUT, result.data);
            store.commit(COMMIT_REMOVE_NOTICES, FieldsKeys.InlineAddress);
        })
            .catch(() => {
            store.commit(COMMIT_CHANGE_LOADING, false);
        });
    };
}
export function OPERATION_CHANGE_INLINE_ADDRESS(store) {
    return function (inlineAddressId) {
        const formFields = store.getState().formFields;
        const country = formFields.find(field => field.key === FieldsKeys.Country);
        if (!country)
            return;
        if (!inlineAddressId) {
            store.commit(COMMIT_SET_NOTICES, { [FieldsKeys.InlineAddress]: { type: 'error', message: 'Укажите улицу и номер дома' } });
            const city = formFields.find(field => field.key === FieldsKeys.City);
            return sendUpdateCartInfo(store, { countryId: country.value, streetAddressId: null, cityId: city === null || city === void 0 ? void 0 : city.value });
        }
        const result = inlineAddressId === null || inlineAddressId === void 0 ? void 0 : inlineAddressId.match(INLINE_ADDRESS_ID_REGEXP);
        if (result) {
            const params = { countryId: country.value, streetAddressId: result[3], cityId: result[1] };
            return sendUpdateCartInfo(store, params).then(() => {
                const el = store.getState().formFieldsRefs[FieldsKeys.Apartment];
                if (el)
                    setTimeout(() => el.focus(), 100);
            });
        }
    };
}
export function OPERATION_CHANGE_ADDRESS_COORDS(store) {
    return async function (addressCoords) {
        store.commit(COMMIT_CHANGE_LOADING, true);
        let countryId = DEFAULT_COUNTRY_ID;
        let cityId = DEFAULT_CITY_ID;
        let streetAddressId = null;
        if (addressCoords) {
            const { formFields } = store.getState();
            const country = formFields.find(({ key }) => key === FieldsKeys.Country);
            if (!country)
                return;
            const { data: { suggestions } } = await getSuggestionsByGeolocate({ lat: addressCoords[0], lon: addressCoords[1] });
            if (suggestions.length === 0) {
                store.commit(COMMIT_SET_NOTICES, { [FieldsKeys.InlineAddress]: { type: 'error', message: ADDRESS_WARNING_TEXT } });
                store.commit(COMMIT_CHANGE_LOADING, false);
                return;
            }
            const suggestion = suggestions.find(({ data: { postalCode, houseKladrId } }) => postalCode !== null && houseKladrId !== null);
            if (!suggestion) {
                store.commit(COMMIT_SET_NOTICES, { [FieldsKeys.InlineAddress]: { type: 'error', message: ADDRESS_WARNING_TEXT } });
                store.commit(COMMIT_CHANGE_LOADING, false);
                return;
            }
            const { settlementKladrId, cityKladrId, houseKladrId, houseFiasId, postalCode } = suggestion.data;
            countryId = country.value;
            cityId = settlementKladrId || cityKladrId;
            streetAddressId = `${houseKladrId}:${houseFiasId}:${postalCode}`;
        }
        const updateCartRequest = addressCoords ? updateCartInfo({ countryId, cityId, streetAddressId }) : updateCartInfo({ streetAddressId: null });
        store.commit(COMMIT_SET_GTM_ADDRESS_EVENT, GtmAddressEvents.MapAddress);
        return updateCartRequest
            .then((result) => {
            const inlineAddress = result.data.changedFormFields.find(({ key }) => key === FieldsKeys.InlineAddress);
            const streetAddress = result.data.changedFormFields.find(({ key }) => key === FieldsKeys.StreetAddress);
            if (inlineAddress === null || inlineAddress === void 0 ? void 0 : inlineAddress.value) {
                store.commit(COMMIT_REMOVE_NOTICES, FieldsKeys.InlineAddress);
            }
            if ((streetAddress === null || streetAddress === void 0 ? void 0 : streetAddress.value) && streetAddress.value.toString().split(':').length >= 3) {
                pushToDataLayer({ event: 'cartSelectValidSuggestion' });
            }
            store.commit(COMMIT_REFRESH_CHECKOUT, result.data);
        })
            .catch(() => {
            store.commit(COMMIT_CHANGE_LOADING, false);
        });
    };
}
export function OPERATION_CHANGE_TERMINAL(store) {
    return function (dlTerminalId) {
        store.commit(COMMIT_CHANGE_LOADING, true);
        return updateCartInfo({ dlTerminalId })
            .then((result) => {
            store.commit(COMMIT_REFRESH_CHECKOUT, result.data);
        })
            .catch(() => {
            store.commit(COMMIT_CHANGE_LOADING, false);
        });
    };
}
export function OPERATION_INIT_CHANGE_CITY(store) {
    return async function () {
        var _a;
        const { formFieldsRefs: refs, formFields } = store.getState();
        const el = (_a = refs[FieldsKeys.InlineAddress]) !== null && _a !== void 0 ? _a : refs[FieldsKeys.City];
        if (!el) {
            const deliveryField = formFields.find(field => field.key === FieldsKeys.DeliveryMethod);
            if ((deliveryField === null || deliveryField === void 0 ? void 0 : deliveryField.value) === DeliveryMethod.Delivery)
                return;
            await store.operate(OPERATION_CHANGE_DELIVERY_METHOD, DeliveryMethod.Delivery);
        }
        setTimeout(() => {
            var _a;
            const refs = store.getState().formFieldsRefs;
            const el = (_a = refs[FieldsKeys.InlineAddress]) !== null && _a !== void 0 ? _a : refs[FieldsKeys.City];
            if (!el)
                return;
            el.scrollIntoView({ behavior: 'smooth' });
            el.focus();
        }, 500);
    };
}
export function OPERATION_LOAD_PICKUP_POINTS(store) {
    return function () {
        const fields = store.getState().formFields;
        const terminalField = fields.find(field => field.key === FieldsKeys.Terminal);
        if (!(terminalField === null || terminalField === void 0 ? void 0 : terminalField.isAvailable))
            return;
        const cityField = fields.find(field => field.key === FieldsKeys.City);
        const deliveryMethod = fields.find(field => field.key === FieldsKeys.DeliveryMethod);
        if ((cityField === null || cityField === void 0 ? void 0 : cityField.value) && (deliveryMethod === null || deliveryMethod === void 0 ? void 0 : deliveryMethod.value)) {
            getPickupPoints(cityField === null || cityField === void 0 ? void 0 : cityField.value, deliveryMethod === null || deliveryMethod === void 0 ? void 0 : deliveryMethod.value).then(({ data }) => {
                const changedFields = fields.map(field => {
                    if (field.key !== FieldsKeys.Terminal)
                        return field;
                    const fieldValue = data.map(({ value }) => value).includes(field.value.toString()) ? field.value : '';
                    return { ...field, value: fieldValue, items: data.map(({ value, title: label }) => ({ value, label })) };
                });
                store.operate(OPERATION_CHANGE_FIELDS, changedFields);
            });
        }
    };
}
export function OPERATION_CHANGE_CUSTOMER_TYPE(store) {
    return async function (customerType) {
        store.commit(COMMIT_CHANGE_LOADING, true);
        return updateCartInfo({ customerType })
            .then((result) => {
            store.commit(COMMIT_REFRESH_CHECKOUT, result.data);
        })
            .catch(() => {
            store.commit(COMMIT_CHANGE_LOADING, false);
        });
    };
}
export function OPERATION_CHANGE_SPLIT_DELIVERY_BY_DATES(store) {
    return async function (value) {
        store.commit(COMMIT_CHANGE_LOADING, true);
        return updateCartInfo({ splitDeliveryByDates: value })
            .then((result) => {
            store.commit(COMMIT_REFRESH_CHECKOUT, result.data);
        })
            .catch(() => {
            store.commit(COMMIT_CHANGE_LOADING, false);
        });
    };
}
function sendUpdateCartInfo(store, params) {
    store.commit(COMMIT_CHANGE_LOADING, true);
    return updateCartInfo(params)
        .then((result) => {
        const inlineAddress = result.data.changedFormFields.find(({ key }) => key === FieldsKeys.InlineAddress);
        const streetAddress = result.data.changedFormFields.find(({ key }) => key === FieldsKeys.StreetAddress);
        if (inlineAddress === null || inlineAddress === void 0 ? void 0 : inlineAddress.value) {
            store.commit(COMMIT_REMOVE_NOTICES, FieldsKeys.InlineAddress);
        }
        if ((streetAddress === null || streetAddress === void 0 ? void 0 : streetAddress.value) && streetAddress.value.toString().split(':').length >= 3) {
            pushToDataLayer({ event: 'cartSelectValidSuggestion' });
        }
        store.commit(COMMIT_REFRESH_CHECKOUT, result.data);
        store.operate(OPERATION_LOAD_PICKUP_POINTS);
    })
        .catch(() => {
        store.commit(COMMIT_CHANGE_LOADING, false);
    });
}
function prepareFormData(store, kpAvailable) {
    var _a, _b, _c;
    const { formFields, ozonPickupId, ozonDeliveryAddress, totalPriceInfo } = store.getState();
    const requestData = formFields.reduce((data, field) => {
        if (field.isAvailable)
            data[field.key] = field.value;
        return data;
    }, {});
    if (requestData.settlement && !((_a = requestData.address) === null || _a === void 0 ? void 0 : _a.startsWith(requestData.settlement))) {
        requestData.address = [requestData.settlement, requestData.address].join(' ');
    }
    if (ozonPickupId !== undefined && ozonDeliveryAddress !== undefined) {
        requestData.delivery_info = {
            ozon_pickup_id: ozonPickupId,
            ozon_delivery_address: ozonDeliveryAddress
        };
    }
    const deliveryDatesRange = (_c = (_b = totalPriceInfo === null || totalPriceInfo === void 0 ? void 0 : totalPriceInfo.deliveryDateEstimate) === null || _b === void 0 ? void 0 : _b.maxDate) === null || _c === void 0 ? void 0 : _c.deliveryDatesRange;
    if (deliveryDatesRange !== undefined) {
        requestData.cart_stats_values = {
            delivery_dates_range: deliveryDatesRange
        };
    }
    requestData.some_random_word_mandala = 'value';
    requestData.kp = kpAvailable;
    return requestData;
}
function pushGtmAddressEvent(store) {
    const fields = store.getState().formFields;
    const deliveryType = fields.find(field => field.key === FieldsKeys.DeliveryMethod);
    if ((deliveryType === null || deliveryType === void 0 ? void 0 : deliveryType.value) !== DeliveryMethod.Delivery)
        return;
    const event = getGtmAddressEvent(store, fields);
    pushToDataLayer({ event: event });
}
function getGtmAddressEvent(store, fields) {
    const streetAddressField = fields.find(field => field.key === FieldsKeys.StreetAddress);
    if (!(streetAddressField === null || streetAddressField === void 0 ? void 0 : streetAddressField.value))
        return GtmAddressEvents.RedAddress;
    const event = store.getState().gtmAddressEvent;
    if (event)
        return event;
    return GtmAddressEvents.AutocompleteAddress;
}
